/**
 * @generated SignedSource<<3c83bf8c1c1199a1e3fe15db67571430>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InlineContentHeroSectionStaticFragment$data = {
  readonly id: string;
  readonly coverPhoto: string | null;
  readonly coverVideo: string | null;
  readonly coverVideoAsset: {
    readonly id: string;
  } | null;
  readonly coverVideoAssetUsage: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "InlineContentHeroSectionStaticFragment";
};
export type InlineContentHeroSectionStaticFragment$key = {
  readonly " $data"?: InlineContentHeroSectionStaticFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InlineContentHeroSectionStaticFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InlineContentHeroSectionStaticFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverPhoto",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverVideo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Asset",
      "kind": "LinkedField",
      "name": "coverVideoAsset",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetUsage",
      "kind": "LinkedField",
      "name": "coverVideoAssetUsage",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": null
};
})();

(node as any).hash = "b48f8da31831160740b5552fb4bf888b";

export default node;
