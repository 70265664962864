import { useTrackAIGenerationStatusQuery } from "@/content/ai/hooks/__generated__/useTrackAIGenerationStatusQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import useInterval from "@utils/hook/useInterval"
import { useCallback } from "react"
import { graphql } from "relay-runtime"

type Props = {
  entityId: GlobalID
  skip?: boolean
}

function useTrackAIGenerationStatus(props: Props) {
  const { entityId, skip = true } = props

  const [{ curriculum }, refetch] =
    Relay.useRefetchableQuery<useTrackAIGenerationStatusQuery>(
      graphql`
        query useTrackAIGenerationStatusQuery($curriculumId: ID!, $skip: Boolean!) {
          curriculum: node(id: $curriculumId) @skip(if: $skip) {
            ... on Curriculum {
              aiGeneration {
                status
              }
            }
          }
        }
      `,
      {
        curriculumId: entityId,
        skip,
      },
      { refetchInBackground: true }
    )

  useInterval(
    () => {
      if (
        !curriculum?.aiGeneration ||
        curriculum?.aiGeneration?.status === "completed" ||
        curriculum?.aiGeneration?.status === "failed"
      )
        return

      refetchStatus()
    },
    5000,
    { shouldStartInterval: !skip }
  )

  const refetchStatus = useCallback(() => {
    refetch({ curriculumId: entityId, skip: false })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId])

  return {
    status: curriculum?.aiGeneration?.status,
    refetchStatus,
  }
}

export default useTrackAIGenerationStatus
