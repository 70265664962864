import { AssetFileType } from "@/admin/media-library/__generated__/AdminMediaLibraryListPagePaginationQuery.graphql"
import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import InlineContentCoverVideoForm from "@/content/inline/hero-section/InlineContentCoverVideoForm"
import InlineCoverPhotoForm from "@/content/inline/hero-section/InlineCoverPhotoForm"
import { InlineContentHeroSectionFormFragment$key } from "@/content/inline/hero-section/__generated__/InlineContentHeroSectionFormFragment.graphql"
import { InlineContentHeroSectionFragment$key } from "@/content/inline/hero-section/__generated__/InlineContentHeroSectionFragment.graphql"
import { InlineContentHeroSectionStaticFragment$key } from "@/content/inline/hero-section/__generated__/InlineContentHeroSectionStaticFragment.graphql"
import useTrackAssetVideoProgress from "@/content/util/useTrackAssetVideoProgress"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { GlobalID } from "@/relay/RelayTypes"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import FileDropzone, {
  IMAGE_AND_VIDEO_FILE_TYPES,
  IMAGE_FILE_TYPES,
  VIDEO_FILE_TYPES,
} from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoSpinner } from "@disco-ui"
import DiscoVideo from "@disco-ui/video/DiscoVideo"
import DiscoInteractivePlayer from "@disco-ui/video/interactive-player/DiscoInteractivePlayer"
import { observer } from "mobx-react-lite"
import { SyntheticEvent } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type InlineContentHeroSectionStaticProps = {
  contentKey: InlineContentHeroSectionStaticFragment$key | null
  contentUsageId?: GlobalID | null
}

const InlineContentHeroSectionStatic = ({
  contentKey,
  contentUsageId,
}: InlineContentHeroSectionStaticProps) => {
  const classes = useStyles()
  const trackAssetVideoProgress = useTrackAssetVideoProgress()

  const content = useFragment<InlineContentHeroSectionStaticFragment$key>(
    graphql`
      fragment InlineContentHeroSectionStaticFragment on Content {
        id
        coverPhoto
        coverVideo
        coverVideoAsset {
          id
        }
        coverVideoAssetUsage {
          id
        }
      }
    `,
    contentKey
  )

  if (!content) return null

  if (content.coverVideoAsset) {
    return (
      <div className={classes.coverContainer}>
        <DiscoInteractivePlayer
          maxHeight={"unset"}
          fullWidth
          radius={"big"}
          poster={content.coverPhoto}
          src={content.coverVideo}
          onVideoTimeUpdate={(seconds: number, percent: number) => {
            if (!content.coverVideoAssetUsage) return
            trackAssetVideoProgress({
              input: {
                assetUsageId: content.coverVideoAssetUsage.id,
                contentId: content.id,
                contentUsageId,
                seconds,
                percent,
              },
            })
          }}
          assetId={content.coverVideoAsset.id}
          testid={"InlineContentHeroSection.cover-video"}
        />
      </div>
    )
  }

  if (content.coverVideo)
    return (
      <div className={classes.coverContainer}>
        <DiscoVideo
          maxHeight={"unset"}
          fullWidth
          radius={"big"}
          poster={content.coverPhoto}
          src={content.coverVideo}
          onVideoTimeUpdate={(seconds: number, percent: number) => {
            if (!content.coverVideoAssetUsage) return
            trackAssetVideoProgress({
              input: {
                assetUsageId: content.coverVideoAssetUsage.id,
                contentId: content.id,
                contentUsageId,
                seconds,
                percent,
              },
            })
          }}
          testid={"InlineContentHeroSection.cover-video"}
        />
      </div>
    )

  if (content.coverPhoto)
    return (
      <div className={classes.coverContainer}>
        <CoverPhoto
          customClassName={classes.coverPhoto}
          coverPhoto={content.coverPhoto}
          testid={"InlineContentHeroSection.cover-photo"}
        />
      </div>
    )

  return null
}

type InlineContentHeroSectionFormProps = {
  form: ContentFormStore
  coverPhotoOnly?: boolean
  coverVideoOnly?: boolean
  contentKey: InlineContentHeroSectionFormFragment$key | null
}

const InlineContentHeroSectionForm = observer<InlineContentHeroSectionFormProps>(
  ({ form, coverPhotoOnly, coverVideoOnly, contentKey }) => {
    const classes = useStyles()

    const content = useFragment<InlineContentHeroSectionFormFragment$key>(
      graphql`
        fragment InlineContentHeroSectionFormFragment on Content {
          ...InlineContentCoverVideoFormFragment
        }
      `,
      contentKey
    )

    if (form.state.content.coverPhoto === "loading") return <DiscoSpinner />

    if (form.state.content.coverVideo) {
      return (
        <InlineContentCoverVideoForm
          form={form}
          classes={classes}
          handleAddImage={handleChangeCover}
          handleRemoveVideo={handleRemoveVideo}
          handleRemoveImage={handleRemoveImage}
          contentKey={content}
        />
      )
    }

    if (form.state.content.coverPhoto) {
      return (
        <InlineCoverPhotoForm
          coverPhoto={form.state.content.coverPhoto}
          classes={classes}
          handleChangeImage={handleChangeCover}
          handleRemoveImage={handleRemoveImage}
        />
      )
    }

    return (
      <FileDropzone
        {...getDropzoneProps()}
        className={classes.fileDropzone}
        suggestedDimensions={{ width: 1200, height: 600 }}
        onUpload={handleChangeCover}
        onMediaSelect={handleChangeCover}
        testid={"InlineContentHeroSection.FileDropzone"}
      />
    )

    function getDropzoneProps() {
      if (coverPhotoOnly) {
        return {
          dropzoneOptions: { accept: IMAGE_FILE_TYPES },
          allowedFileTypes: ["image"] as AssetFileType[],
          message: "Drop or upload a cover image",
        }
      }
      if (coverVideoOnly) {
        return {
          dropzoneOptions: { accept: VIDEO_FILE_TYPES },
          allowedFileTypes: ["video"] as AssetFileType[],
          message: "Drop or upload a cover video",
        }
      }

      return {
        dropzoneOptions: { accept: IMAGE_AND_VIDEO_FILE_TYPES },
        allowedFileTypes: ["image", "video"] as AssetFileType[],
        message: "Drop or upload a cover image or video",
      }
    }

    function handleRemoveImage(e: SyntheticEvent) {
      e.preventDefault()
      form.state.content.coverPhoto = null
      form.state.content.coverPhotoAssetId = null
    }

    function handleRemoveVideo(e: SyntheticEvent) {
      e.preventDefault()
      form.state.content.coverPhoto = null
      form.state.content.coverPhotoAssetId = null
      form.state.content.coverVideo = null
      form.state.content.coverVideoAssetId = null
    }

    function handleChangeCover(result: MediaResult) {
      // If the file is a cover image,
      if (result.url.match(/.(png|jpeg|jpg|gif)$/)) {
        if (coverVideoOnly) return

        handleAddCoverPhoto(result)
        return
      }

      if (coverPhotoOnly) return

      // If the file is a video
      handleAddCoverVideo(result)
    }

    function handleAddCoverPhoto(result: MediaResult) {
      form.state.content.coverPhoto = result.url
      form.state.content.coverPhotoAssetId = result.id
    }

    function handleAddCoverVideo(result: MediaResult) {
      form.state.content.coverVideo = result.url
      form.state.content.coverVideoAssetId = result.id
    }
  }
)

type InlineContentHeroSectionProps = {
  contentKey?: InlineContentHeroSectionFragment$key | null
  contentUsageId?: GlobalID | null
} & Partial<Omit<InlineContentHeroSectionFormProps, "contentKey">>

function InlineContentHeroSection(props: InlineContentHeroSectionProps) {
  const content = useFragment<InlineContentHeroSectionFragment$key>(
    graphql`
      fragment InlineContentHeroSectionFragment on Content {
        ...InlineContentHeroSectionStaticFragment
        ...InlineContentHeroSectionFormFragment
      }
    `,
    props.contentKey || null
  )

  if (props.form)
    return (
      <InlineContentHeroSectionForm
        form={props.form}
        coverPhotoOnly={props.coverPhotoOnly}
        coverVideoOnly={props.coverVideoOnly}
        contentKey={content}
      />
    )
  return (
    <InlineContentHeroSectionStatic
      contentKey={content}
      contentUsageId={props.contentUsageId}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  coverContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  coverPhoto: {
    borderRadius: theme.measure.borderRadius.big,
    width: "100%",
    backgroundSize: "cover",
    maxHeight: "336px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "200px",
    },
  },
  fileDropzone: {
    width: "100%",
  },
}))

export default observer(InlineContentHeroSection)
