/**
 * @generated SignedSource<<f7f5dc26fcbb1c1bc2a6dc31689a0dbc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "content" | "collection" | "curriculum" | "member_onboarding" | "product_app" | "dashboard_block_content" | "organization" | "content_usage_confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentThumbnailWithDetails_ContentUsageFragment$data = {
  readonly id: string;
  readonly dueAt: string | null;
  readonly entity: ContentUsageEntity;
  readonly product: {
    readonly name: string;
  } | null;
  readonly module: {
    readonly isCurriculumModule: boolean;
    readonly isCollectionModule: boolean;
    readonly name: string | null;
  } | null;
  readonly " $fragmentType": "ContentThumbnailWithDetails_ContentUsageFragment";
};
export type ContentThumbnailWithDetails_ContentUsageFragment$key = {
  readonly " $data"?: ContentThumbnailWithDetails_ContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentThumbnailWithDetails_ContentUsageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentThumbnailWithDetails_ContentUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "module",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCurriculumModule",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCollectionModule",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "cfa820a7fd1b7b1d414808122c1ed363";

export default node;
