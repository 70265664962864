import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import PlayArrow from "@/core/ui/iconsax/bold/play.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import FileDropzone, { IMAGE_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoFormControl, DiscoText, DiscoTextButton, DiscoTooltip } from "@disco-ui"
import DiscoSwitch from "@disco-ui/switch/DiscoSwitch"
import { useTheme } from "@material-ui/core"
import { ASPECT_RATIOS } from "@utils/image/imageConstants"
import { TestIDProps } from "@utils/typeUtils"
import classnames from "classnames"
import { observer } from "mobx-react-lite"

interface Props extends TestIDProps {
  form: ContentFormStore
  label?: string | React.ReactNode | null
  showPlayIcon?: boolean
  classes?: {
    checkbox?: string
  }
}

function ContentFormThumbnailSection({
  form,
  label,
  showPlayIcon = true,
  classes: propClasses,
  testid,
}: Props) {
  const theme = useTheme()
  const classes = useStyles()

  const showPlayIconIsDisabled =
    !form.state.content.thumbnailUrl || !form.state.content.coverVideo

  const displayPlayIcon =
    form.state.content.showCoverPlayIcon && form.state.content.coverVideo

  return (
    <div className={classes.thumbnailSection}>
      <DiscoFormControl
        className={classes.showPlayIconSection}
        label={
          typeof label === "string" ? (
            <DiscoText variant={"body-sm-500"}>{label}</DiscoText>
          ) : (
            label
          )
        }
        errorMessages={form.errorsByField.thumbnailUrl}
      >
        {showPlayIcon && (
          <DiscoSwitch
            className={classnames(propClasses?.checkbox, {
              [classes.disabledCheckbox]: showPlayIconIsDisabled,
            })}
            data-testid={`${testid}.ThumbnailField.show-play-icon`}
            name={"toggle-show-cover-play-icon"}
            checked={form.state.content.showCoverPlayIcon!}
            onChange={() =>
              (form.state.content.showCoverPlayIcon =
                !form.state.content.showCoverPlayIcon)
            }
            label={
              <div className={classes.labelContainer}>
                <DiscoText variant={"body-sm"} color={"text.secondary"}>
                  {"Show the 'Play' icon"}
                </DiscoText>
                {showPlayIconIsDisabled && (
                  <DiscoTooltip
                    content={`Add a thumbnail and cover video to enable this button`}
                    buttonProps={{ style: { marginLeft: theme.spacing(1) } }}
                  />
                )}
              </div>
            }
            disabled={showPlayIconIsDisabled}
          />
        )}
      </DiscoFormControl>

      {form.state.content.thumbnailUrl ? (
        <div className={classes.thumbnailContainer}>
          <div className={classes.thumbnailPreview}>
            <CoverPhoto
              coverPhoto={form.state.content.thumbnailUrl}
              testid={`${testid}.ThumbnailField.preview`}
            />
            {displayPlayIcon && (
              <div className={classes.playIcon}>
                <PlayArrow />
              </div>
            )}
          </div>
          <DiscoTextButton
            onClick={handleRemoveThumbnail}
            className={classes.removeThumbnailButton}
          >
            {"Remove"}
          </DiscoTextButton>
        </div>
      ) : (
        <FileDropzone
          className={classes.thumbnailDropzone}
          dropzoneOptions={{ accept: IMAGE_FILE_TYPES }}
          message={"Drop or upload an image"}
          testid={`${testid}.ThumbnailField.thumbnail-upload`}
          cropperProps={{
            stencilProps: { aspectRatio: ASPECT_RATIOS.CURRICULUM_ITEM_THUMBNAIL },
          }}
          onUpload={(result: MediaResult) => {
            form.state.content.thumbnailUrl = result.url
            form.state.content.thumbnailAssetId = result.id
          }}
          onMediaSelect={(result: MediaResult) => {
            form.state.content.thumbnailUrl = result.url
            form.state.content.thumbnailAssetId = result.id
          }}
          allowedFileTypes={["image"]}
          mediaLibraryButtonProps={{ variant: "outlined", color: "grey" }}
        />
      )}
    </div>
  )

  function handleRemoveThumbnail() {
    form.state.content.thumbnailUrl = null
    form.state.content.thumbnailAssetId = null
  }
}

const useStyles = makeUseStyles((theme) => ({
  thumbnailSection: {
    display: "grid",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
  },
  showPlayIconSection: {
    marginBottom: theme.spacing(1.5),
  },
  thumbnailPreview: {
    position: "relative",
  },
  playIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    display: "grid",
    alignItems: "center",
    padding: theme.spacing(1),
    "& svg": {
      height: "30px",
      width: "30px",
      color: theme.palette.common.white,
    },
  },
  thumbnailContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
  },
  removeThumbnailButton: {
    alignSelf: "flex-end",
    marginTop: theme.spacing(1.5),
  },
  thumbnailDropzone: {
    marginBottom: theme.spacing(1),
  },
  disabledCheckbox: {
    opacity: "0.5",
  },
}))

export default observer(ContentFormThumbnailSection)
