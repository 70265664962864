/**
 * @generated SignedSource<<46f40f84de2a55ca425318600bd4bcf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AIGenerationStatus = "pending" | "running" | "completed" | "failed" | "%future added value";
export type useTrackAIGenerationStatusQuery$variables = {
  curriculumId: string;
  skip: boolean;
};
export type useTrackAIGenerationStatusQuery$data = {
  readonly curriculum?: {
    readonly aiGeneration?: {
      readonly status: AIGenerationStatus;
    } | null;
  } | null;
};
export type useTrackAIGenerationStatusQuery = {
  variables: useTrackAIGenerationStatusQuery$variables;
  response: useTrackAIGenerationStatusQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "curriculumId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "curriculumId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useTrackAIGenerationStatusQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "curriculum",
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AIGeneration",
                    "kind": "LinkedField",
                    "name": "aiGeneration",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Curriculum",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useTrackAIGenerationStatusQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "curriculum",
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AIGeneration",
                    "kind": "LinkedField",
                    "name": "aiGeneration",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Curriculum",
                "abstractKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "02e2d22313277418d9692a5ab70f95ae",
    "id": null,
    "metadata": {},
    "name": "useTrackAIGenerationStatusQuery",
    "operationKind": "query",
    "text": "query useTrackAIGenerationStatusQuery(\n  $curriculumId: ID!\n  $skip: Boolean!\n) {\n  curriculum: node(id: $curriculumId) @skip(if: $skip) {\n    __typename\n    ... on Curriculum {\n      aiGeneration {\n        status\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "352de54114b3751b5d37654401a1c756";

export default node;
